import { Box, Grid, Typography } from "@mui/material";
import {
	facebook,
	instagram,
	tiktok,
	twitter,
} from "../../commons/constants/redirects";
import { isMobile, isTablet } from "react-device-detect";
import { IProps } from "../../types/CommonTypes";
import { getImageById } from "../../commons/constants/helpers";

const Footer = ({ item }: IProps) => {
	const styles = {
		footer: {
			backgroundImage: `url(${getImageById("banner", item)})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			color: "#ffffff",
			paddingTop: "20px",
			paddingBottom: "10px",
			marginTop: "auto",
			width: "100%",
		},
	};

	return (
		<footer style={styles.footer}>
			<Grid
				container
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Grid item xs={12}>
					<img
						src={getImageById("name2", item)}
						alt="Small"
						style={{
							width: "250px",
							height: "auto",
							margin: 0,
							padding: 0,
						}}
					/>
				</Grid>
				{isMobile && !isTablet ? (
					<Grid
						container
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							alignContent: "center",
							margin: isMobile ? "0px" : "50px",
							marginTop: "20px",
						}}
					>
						<Grid
							item
							xs={12}
							display={"flex"}
							flexDirection={"row"}
							justifyContent={"space-around"}
							sx={{ width: "100%" }}
						>
							<SocialItem
								label="Twitter"
								source={require("../../assets/social/twitter.png")}
								href={twitter}
							/>
							<SocialItem
								label="Instagram"
								source={require("../../assets/social/instagram.png")}
								href={instagram}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							display={"flex"}
							flexDirection={"row"}
							justifyContent={"space-around"}
							sx={{ width: "100%" }}
						>
							<SocialItem
								label="TikTok"
								source={require("../../assets/social/tiktok.png")}
								href={tiktok}
							/>
							<SocialItem
								label="Facebook"
								source={require("../../assets/social/facebook.png")}
								href={facebook}
							/>
						</Grid>
					</Grid>
				) : (
					<Grid
						item
						xs={12}
						md={10}
						style={{
							display: "flex",
							flexDirection:
								isMobile && !isTablet ? "column" : "row",
							justifyContent: "space-between",
							margin: isMobile ? "0px" : "50px",
							width: "90%",
							marginTop: "20px",
						}}
					>
						<SocialItem
							label="Twitter"
							source={require("../../assets/social/twitter.png")}
							href={twitter}
						/>
						<SocialItem
							label="Instagram"
							source={require("../../assets/social/instagram.png")}
							href={instagram}
						/>
						<SocialItem
							label="TikTok"
							source={require("../../assets/social/tiktok.png")}
							href={tiktok}
						/>
						<SocialItem
							label="Facebook"
							source={require("../../assets/social/facebook.png")}
							href={facebook}
						/>
					</Grid>
				)}
				<Grid item style={{ marginTop: isMobile ? "20px" : "0px" }}>
					<Typography
						variant="body2"
						color="white"
						textAlign={"center"}
					>
						© {new Date().getFullYear()} Daniel Briceño <br />{" "}
						Prohibida su distribución para fines maliciosos <br />
						Desarrollado por{" "}
						<a
							style={{ textDecoration: "none", color: "white" }}
							href="https://survivorslabs.com"
							target="__blank"
						>
							{" "}
							Survivors Labs
						</a>
					</Typography>
				</Grid>
			</Grid>
		</footer>
	);
};

const SocialItem = ({ label, source, href }: any) => {
	return (
		<Grid
			item
			display={"flex"}
			flexDirection={"row"}
			justifyContent={"flex-start"}
			sx={{ marginTop: "5px" }}
		>
			<a
				href={href}
				target="__blank"
				style={{
					textDecoration: "none",
					color: "white",
					display: "flex",
					justifyContent: "flex-start",
				}}
			>
				<img
					style={{
						width: isMobile ? "30px" : "40px",
						height: isMobile ? "30px" : "40px",
						marginRight: "10px",
					}}
					src={source}
					alt=""
				/>
				<Typography
					variant={isMobile ? "h6" : "h4"}
					fontWeight={600}
					color="white"
					textAlign={"left"}
				>
					{label}
				</Typography>
			</a>
		</Grid>
	);
};

export default Footer;
