import { Grid } from "@mui/material";
import TemporaryDrawer from "../components/dashboard/TemporaryDrawer";

export default function Dashboard() {
	return (
		<Grid container>
			<Grid item>
				<TemporaryDrawer />
			</Grid>
		</Grid>
	);
}
