import { useState, useEffect } from "react";

import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	Auth,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

interface AuthError {
	code: string;
	message: string;
}

interface AuthResult {
	user: any;
	error: AuthError | null;
	loading: boolean;
	signUp: (email: string, password: string) => Promise<void>;
	signIn: (email: string, password: string) => Promise<void>;
	signOut: () => Promise<void>;
}

const useAuth = (): AuthResult => {
	const [user, setUser] = useState<any>(null);
	const [error, setError] = useState<AuthError | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	useEffect(() => {
		const auth: Auth = getAuth();

		// Observador de cambio de estado de autenticación
		const unsubscribe = auth.onAuthStateChanged((user) => {
			console.log("Auth state changed", user);
			setUser(user);
			setLoading(false);
		});

		// Limpieza del efecto al desmontar el componente
		return () => unsubscribe();
	}, []);

	const signUp = async (email: string, password: string): Promise<void> => {
		try {
			const auth: Auth = getAuth();
			setLoading(true);
			setError(null);

			// Crear usuario con correo y contraseña
			await createUserWithEmailAndPassword(auth, email, password);
		} catch (error: any) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	const signIn = async (email: string, password: string): Promise<void> => {
		try {
			const auth: Auth = getAuth();
			setLoading(true);
			setError(null);

			// Iniciar sesión con correo y contraseña
			await signInWithEmailAndPassword(auth, email, password);
			navigate("/dashboard");
		} catch (error: any) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	const signOut = async (): Promise<void> => {
		try {
			const auth: Auth = getAuth();
			setLoading(true);
			setError(null);

			// Cerrar sesión
			await auth.signOut();
		} catch (error: any) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	return {
		user,
		error,
		loading,
		signUp,
		signIn,
		signOut,
	};
};

export default useAuth;
