import { Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import { IProps } from "../../types/CommonTypes";
import { getImageById, getTextById } from "../../commons/constants/helpers";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet-async";

export default function About({ item }: IProps) {
	return (
		<Grid id="about" container>
			<Helmet>
				<title>Daniel Briceño</title>
				<meta
					name="description"
					content="Abogado, especialista en derecho público, magister en análisis político y electoral, hago parte de la oposición ciudadana, control, denuncia y firmeza por Bogotá y Colombia"
				/>
			</Helmet>
			<Grid
				style={{ display: "flex", marginTop: "30px" }}
				item
				md={12}
				xs={12}
				justifyContent="center"
			>
				<Fade fadeIn>
					<img
						style={{ alignSelf: "center", width: "40%" }}
						src={getImageById("title", item)}
						alt="name"
					/>
				</Fade>
			</Grid>
			<Grid
				item
				style={{
					display: "flex",
					justifyContent: "center",
				}}
				xs={12}
				md={12}
			>
				<Fade fadeIn>
					<p
						style={{
							textAlign: isMobile ? "justify" : "center",
							width: "80%",
						}}
						dangerouslySetInnerHTML={{
							__html: getTextById("description", item) || "",
						}}
					></p>
				</Fade>
			</Grid>
		</Grid>
	);
}
