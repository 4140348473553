import { Grid, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

export interface Column {
	field: string;
	headerName: string;
	width?: number;
	type?: "string" | "number" | "date" | "dateTime";
}

interface TableProps {
	columns: Column[];
	rows: GridRowsProp;
	pageSize?: number;
	headerName: string;
}

export const CustomTable = ({
	columns,
	rows,
	pageSize = 10,
	headerName,
}: TableProps) => {
	const columnsWithRenderCell: GridColDef[] = columns.map((column) => {
		if (column.headerName === headerName) {
			return {
				...column,
				renderCell: (params) => (
					<Tooltip
						title={params.value as string}
						placement="top-start"
					>
						<span>{params.value as string}</span>
					</Tooltip>
				),
			};
		} else {
			return {
				...column,
				renderCell: (params) => (
					<>
						<span>{params.value}</span>
					</>
				),
			};
		}
	});

	return (
		<Grid
			container
			style={{
				height: 600,
			}}
		>
			<DataGrid rows={rows} columns={columnsWithRenderCell} pagination />
		</Grid>
	);
};
