import { ThemeProvider } from "@emotion/react";
import Router from "./commons/navigation/Router";
import { theme } from "./commons/constants/theme";
import "./commons/firebase/FirebaseConfig";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<HelmetProvider>
				<Router />
			</HelmetProvider>
			<Helmet>
				<title>Daniel Briceño</title>
				<meta
					name="description"
					content="Daniel Briceño. Abogado, especialista en derecho público, mágister en análisis político y electoral, hago parte de la oposición ciudadana, control, denuncia y firmeza por Bogotá y Colombia"
				/>
			</Helmet>
		</ThemeProvider>
	);
}

export default App;
