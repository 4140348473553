import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<HelmetProvider>
			<div>
				<Helmet>
					<title>Daniel Briceño</title>
					<meta
						name="description"
						content="Abogado, especialista en derecho público, magister en análisis político y electoral, hago parte de la oposición ciudadana, control, denuncia y firmeza por Bogotá y Colombia"
					/>
				</Helmet>
			</div>
		</HelmetProvider>
		<App />
	</React.StrictMode>
);

reportWebVitals();
