import React from "react";
import { Typography, TextField, Modal, Box, Button } from "@mui/material";
import { IReport } from "../../../types/CommonTypes";

const InfoView = ({
	report,
	isOpen,
	onClose,
}: {
	report?: IReport;
	isOpen: boolean;
	onClose: () => void;
}) => {
	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 500,
					bgcolor: "background.paper",
					p: 3,
				}}
			>
				<Typography variant="h6">{report?.name}</Typography>
				<Typography variant="subtitle1">
					{report?.phoneNumber}
				</Typography>
				<Typography variant="subtitle1">{report?.date}</Typography>
				<TextField
					multiline
					rows={10}
					variant="outlined"
					label="Report"
					value={report?.report}
					fullWidth
					InputProps={{
						readOnly: true,
					}}
					sx={{ mt: 2 }}
				/>
				<Button
					style={{ marginTop: "10px" }}
					variant="contained"
					fullWidth
					onClick={onClose}
				>
					Cerrar
				</Button>
			</Box>
		</Modal>
	);
};

export default InfoView;
