import { Grid, Typography, Button } from "@mui/material";
import { useRef } from "react";

interface IItemProps {
	label: string;
	source: string;
	onUpdate: any;
}
const Item = (props: IItemProps) => {
	const { label, source, onUpdate } = props;
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (event: any) => {
		const file = event.target.files[0];

		if (file) {
			if (file.type.startsWith("image/")) {
				onUpdate(file);
			} else {
				alert("Por favor, selecciona un archivo de imagen.");
			}
		}
	};

	return (
		<Grid
			item
			md={3}
			xs={12}
			display="flex"
			style={{ margin: "30px" }}
			alignItems="flex-end"
		>
			<Grid item>
				<Typography variant="h6" textAlign="center">
					{label?.toUpperCase() || ""}
				</Typography>

				<img style={{ width: "100%" }} src={source} alt="banner" />
				<input
					type="file"
					onChange={handleFileChange}
					style={{ display: "none" }}
					ref={fileInputRef}
				/>

				<Button
					fullWidth
					variant="contained"
					onClick={handleButtonClick}
				>
					Reemplazar imagen
				</Button>
			</Grid>
		</Grid>
	);
};

export default Item;
