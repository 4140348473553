import { useState, ChangeEvent, FormEvent } from "react";
import {
	Button,
	Box,
	Typography,
	Grid,
	CircularProgress,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import CustomField from "../forms/CustomField";
import { isMobile } from "react-device-detect";
import Carousel from "react-material-ui-carousel";
import { IProps } from "../../types/CommonTypes";
import {
	formatDate,
	getImageById,
	getTextById,
} from "../../commons/constants/helpers";
import useServices from "../../commons/hooks/useServices";
import HeadShake from "react-reveal/HeadShake";
import VideoItem from "../videos/VideoItem";
import { toast } from "react-toastify";

interface FormValues {
	name: string;
	phoneNumber: string;
	message: string;
	city: string;
	date: string;
}

const initialState = {
	name: "",
	phoneNumber: "",
	message: "",
	city: "",
	date: "",
};

const Contact = ({ item }: IProps) => {
	const [formValues, setFormValues] = useState<FormValues>(initialState);
	const { handleCreateContact, loading } = useServices();
	const [checked, setChecked] = useState<boolean>(false);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === "phoneNumber") {
			setFormValues((prevValues) => ({
				...prevValues,
				[name]: value.replace(/[^0-9]/g, ""),
			}));
		} else {
			setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
		}
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		await handleCreateContact({
			...formValues,
			date: formatDate(new Date().toString()),
		});
		setFormValues(initialState);
	};

	return (
		<Grid
			container
			id="contacto"
			style={{
				backgroundImage: `url(${getImageById(
					"banner",
					item
				)}), linear-gradient(to bottom, white 50%, white 50%)`,
				backgroundSize: "100% 60%, 100% 100%",
				backgroundPosition: "top",
				backgroundRepeat: "no-repeat",
				paddingTop: "20px",
			}}
		>
			<Grid item md={6} xs={12}>
				{isMobile && (
					<Grid
						item
						style={{
							paddingLeft: isMobile ? "10px" : "80px",
						}}
					>
						<Typography
							color={"white"}
							variant={isMobile ? "h4" : "h2"}
							fontWeight={800}
						>
							{getTextById("subtitle", item)}
						</Typography>
						<Carousel>
							{item.videos?.map((video, index) => (
								<VideoItem
									key={index}
									url={video.url}
									title={video.title}
								/>
							))}
						</Carousel>
					</Grid>
				)}
				<Grid item style={{ paddingLeft: isMobile ? "10px" : "80px" }}>
					<Typography
						color={"white"}
						variant={isMobile ? "h4" : "h2"}
						fontFamily="Source Sans Variable"
					>
						{getTextById("title1", item)}
					</Typography>
					<Typography
						color={"white"}
						variant={isMobile ? "h4" : "h2"}
						fontWeight={800}
						fontFamily="Gotham Black"
					>
						{getTextById("title2", item)}
					</Typography>
				</Grid>
				{!isMobile && (
					<Grid
						item
						style={{
							paddingLeft: isMobile ? "10px" : "80px",
							marginTop: isMobile ? "30px" : "100px",
						}}
					>
						<Typography
							color={"white"}
							variant={isMobile ? "h4" : "h2"}
							fontWeight={800}
							fontFamily="Gotham Black"
						>
							{getTextById("subtitle", item)}
						</Typography>
					</Grid>
				)}
				{!isMobile && (
					<Carousel>
						{item.videos?.map((video, index) => (
							<VideoItem
								key={index}
								url={video.url}
								title={video.title}
							/>
						))}
					</Carousel>
				)}
			</Grid>
			<Grid
				item
				md={6}
				xs={12}
				style={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					padding: "10px",
				}}
			>
				<Box
					maxWidth="400px"
					margin="0 auto"
					padding={2}
					style={{
						padding: "20px",
						borderRadius: "30px",
						backgroundColor: "white",
					}}
				>
					<HeadShake delay={2000}>
						<form onSubmit={handleSubmit}>
							<CustomField
								fullWidth
								label="Nombre"
								name="name"
								value={formValues.name}
								onChange={handleChange}
								inputProps={{ maxLength: 100 }}
							/>
							<CustomField
								fullWidth
								label="Número de celular"
								name="phoneNumber"
								value={formValues.phoneNumber}
								onChange={handleChange}
								inputProps={{ maxLength: 11 }}
							/>
							<CustomField
								fullWidth
								label="Ciudad"
								name="city"
								value={formValues.city}
								onChange={handleChange}
								inputProps={{ maxLength: 100 }}
							/>
							<CustomField
								fullWidth
								label="Mensaje"
								name="message"
								value={formValues.message}
								onChange={handleChange}
								multiline
								rows={6}
								inputProps={{ maxLength: 3000 }}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={checked}
										onChange={(e) =>
											setChecked(e.target.checked)
										}
										color="primary"
									/>
								}
								label="Acepto el uso y tratamiento de datos personales"
							/>
							{!loading ? (
								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									style={{
										borderRadius: "20px",
										display: checked ? "flex" : "none",
									}}
								>
									Enviar
								</Button>
							) : (
								<Box
									style={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<CircularProgress />
								</Box>
							)}
						</form>
					</HeadShake>
				</Box>
			</Grid>
		</Grid>
	);
};

export default Contact;
