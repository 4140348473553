import { useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function useFirebase() {
	const [loading, setLoading] = useState<boolean>(false);

	// Función para cargar un archivo a Cloud Storage de Firebase
	const uploadFileToStorage = (file: File, path: string): Promise<string> => {
		setLoading(true);
		const storage = getStorage();
		const storageRef = ref(storage, path);

		return new Promise<string>((resolve, reject) => {
			uploadBytes(storageRef, file)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							resolve(downloadURL);
						})
						.catch((error) => {
							reject(error);
						});
				})
				.catch((error) => {
					console.log("Error en funcion de carga", error);
					reject(error);
				})
				.finally(() => {
					setLoading(false);
				});
		});
	};

	return { loading, uploadFileToStorage };
}
