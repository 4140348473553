import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { getIdByUrl } from "../../commons/constants/helpers";

interface IVideoItem {
	url: string;
	title: string;
	md?: number;
}

export default function VideoItem({ url, title, md = 12 }: IVideoItem) {
	const [isVideoOpen, setIsVideoOpen] = useState(false);
	const youtubeVideoId = getIdByUrl(url);
	const videoPreview = `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`;

	const handleVideoClick = () => {
		setIsVideoOpen(true);
	};

	const handleCloseVideo = () => {
		setIsVideoOpen(false);
	};

	return (
		<Grid
			item
			md={md}
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				marginTop: "15px",
				height: isVideoOpen ? "100vh" : "auto",
			}}
		>
			{isVideoOpen ? (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 9999,
						backgroundColor: "rgba(0, 0, 0, 0.5)", // fondo semi transparente
					}}
					onClick={handleCloseVideo}
				>
					<iframe
						title="video"
						width={isMobile ? "90%" : "80%"}
						height={isMobile ? "400px" : "90%"}
						src={`https://www.youtube.com/embed/${youtubeVideoId}`}
						allowFullScreen
						style={{
							position: "relative",
							zIndex: 10000,
							borderRadius: "20px",
						}}
						onClick={(e) => e.stopPropagation()} // para prevenir el cierre del video al hacer clic sobre él
					/>
				</div>
			) : (
				<div
					style={{
						display: "flex",
						width: isMobile ? "95%" : "80%",
						justifyContent: "center",
						borderRadius: "20px",
						alignSelf: "center",
						height: "300px",
						cursor: "pointer",
					}}
					onClick={handleVideoClick}
				>
					<div
						style={{
							position: "relative",
							width: "100%",
							height: "100%",
						}}
					>
						<img
							src={videoPreview}
							alt="video preview"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								alignSelf: "center",
								borderRadius: "20px",
							}}
						/>
						<div
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							}}
						>
							<img
								src="https://cdn-icons-png.flaticon.com/128/3039/3039386.png"
								alt="play icon"
								style={{
									width: "50px",
									height: "50px",
								}}
							/>
						</div>
					</div>
				</div>
			)}
			<Grid
				item
				style={{
					// backgroundColor: lightBlue,
					width: "80%",
					alignSelf: "center",
					marginTop: 10,
				}}
			>
				<Typography
					variant="h5"
					fontWeight="bold"
					textAlign="center"
					color="#000"
				>
					{title}
				</Typography>
			</Grid>
		</Grid>
	);
}
