import { Box, CircularProgress } from "@mui/material";
import About from "../components/about/About";
import Banner from "../components/banner/Banner";
import Footer from "../components/footer/Footer";
import ReportForm from "../components/forms/ReportForm";
import Reports from "../components/reports/Reports";
import Contact from "../components/social/Contact";
import VideoSection from "../components/videos/VideoSection";
import useWebsite from "../services/useWebsite";
import "./styles/Home.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default function Home() {
	const { website } = useWebsite();

	const location = useLocation();

	useEffect(() => {
		if (location.pathname === "/denuncias") {
			setTimeout(() => {
				document
					.getElementById("denuncias")
					?.scrollIntoView({ behavior: "smooth" });
			}, 1500);
		} else if (location.pathname === "/contacto") {
			setTimeout(() => {
				document
					.getElementById("contacto")
					?.scrollIntoView({ behavior: "smooth" });
			}, 1500);
		} else if (location.pathname.includes("/#about")) {
			setTimeout(() => {
				document
					.getElementById("about")
					?.scrollIntoView({ behavior: "smooth" });
			}, 1500);
		}
	}, [location]);

	return (
		<div className="container-home">
			<Helmet>
				<title>Daniel Briceño</title>
				<meta
					name="description"
					content="Abogado, especialista en derecho público, magister en análisis político y electoral, hago parte de la oposición ciudadana, control, denuncia y firmeza por Bogotá y Colombia"
				/>
			</Helmet>
			{website ? (
				<>
					<Banner item={website.banner} />
					<About item={website.about} />
					<VideoSection item={website.testimonials} />
					<Reports item={website.reports} />
					<ReportForm item={website.reportForm} />
					<Contact item={website.contact} />
					<Footer item={website.footer} />
				</>
			) : (
				<div
					style={{
						width: "100%",
						backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/danielbricen-cc9d9.appspot.com/o/assets%2Fbanner%2Fbanner.png?alt=media&token=9950b1e7-8a99-4c80-adda-a9de8b825894")`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						minHeight: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Box sx={{ display: "flex" }}>
						<CircularProgress
							color="inherit"
							style={{ color: "white" }}
						/>
					</Box>
				</div>
			)}
		</div>
	);
}
