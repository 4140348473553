import { Checkbox, FormControlLabel, Grid, IconButton } from "@mui/material";
import { Column, CustomTable } from "../generic/CustomTable";
import useServices from "../../commons/hooks/useServices";
import { useEffect, useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { IReport } from "../../types/CommonTypes";
import InfoView from "./generic/InfoView";
import Title from "../generic/Title";
import GetAppIcon from "@mui/icons-material/GetApp";
import useStorage from "../../commons/hooks/useStorage";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

export default function DashboardViewReports() {
	const columns: Column[] = [
		{ field: "date", headerName: "Fecha", width: 150 },
		{ field: "name", headerName: "Nombre", width: 150 },
		{ field: "phoneNumber", headerName: "Teléfono", width: 150 },
		{ field: "report", headerName: "Denuncia", width: 400 },
		{ field: "action", headerName: "Acción", width: 90 },
		{ field: "checked", headerName: "Revisada", width: 90 },
	];

	const { reports, getReports, updateRow } = useServices();
	const [selectedRow, setSelectedRow] = useState<IReport>();
	const { downloadFile, downloading } = useStorage();

	useEffect(() => {
		getReports();
	}, []);

	const handleView = (item: any) => {
		setSelectedRow(item);
	};

	const setChecked = async (id: string, checked: boolean) => {
		updateRow(id, "reports", checked);
	};

	const reportsWithActions = reports.map((item: any) => ({
		...item,
		checked: (
			<FormControlLabel
				control={
					<Checkbox
						checked={item.checked}
						onChange={(e) => setChecked(item.id, e.target.checked)}
						color="primary"
					/>
				}
				label=""
			/>
		),
		action: (
			<>
				<IconButton onClick={() => handleView(item)}>
					<RemoveRedEye />
				</IconButton>
				{item.file && (
					<IconButton
						onClick={() => downloadFile(item.file)}
						color="secondary"
						aria-label="descargar"
						disabled={downloading}
					>
						{!downloading ? <GetAppIcon /> : <WatchLaterIcon />}
					</IconButton>
				)}
			</>
		),
	}));

	return (
		<Grid container>
			<Title>Tabla de denuncias</Title>
			<InfoView
				isOpen={selectedRow !== undefined}
				onClose={() => setSelectedRow(undefined)}
				report={selectedRow}
			/>

			<CustomTable
				columns={columns}
				rows={reportsWithActions}
				headerName="Denuncia"
			/>
		</Grid>
	);
}
