import { Button, Grid, TextField } from "@mui/material";
import { IItem, IProps } from "../../types/CommonTypes";
import Title from "../generic/Title";
import Item from "./generic/ImageItem";
import { useEffect, useState } from "react";
import useFirebase from "../../commons/firebase/useFirebase";
import TextItem from "./generic/TextItem";
import { getRandomId } from "../../commons/constants/helpers";

export default function DashboardContact({ item, onChange }: IProps) {
	const [data, setData] = useState<IItem>(item);
	const { uploadFileToStorage } = useFirebase();

	useEffect(() => {
		onChange && onChange(data);
	}, [data]);

	const handleNewPic = async (id: string, file: any) => {
		try {
			let path = "/assets/contact/";
			const downloadURL = await uploadFileToStorage(
				file,
				path + file.name
			);
			let updated: any = data.images.map((item) => {
				if (item.id === id) {
					return {
						id: id,
						name: getRandomId(),
						path: path,
						url: downloadURL,
					};
				}
				return item;
			});

			setData({ ...data, images: updated });
		} catch (error) {
			console.log("Error al cargar el archivo:", error);
		}
	};

	const handleTextChange = (id: string, value: string) => {
		let updated: any = data.texts.map((item) => {
			if (item.id === id) {
				return {
					...item,
					text: value,
				};
			}
			return item;
		});

		setData({ ...data, texts: updated });
	};

	const handleAddNew = () => {
		setData({
			...data,
			videos: [
				...(data.videos || []),
				{
					id: data.videos?.length.toString() || "0",
					url: "",
					title: "",
				},
			],
		});
	};

	const handleText = (id: string, value: string, field: string) => {
		let updated: any = data.videos?.map((video) => {
			if (video.id === id) {
				return {
					...video,
					[field]: value,
				};
			}
			return video;
		});

		setData({ ...data, videos: updated });
	};

	const deleteVideo = (id: string) => {
		let filtered = data.videos?.filter((item) => item.id !== id);
		setData({ ...data, videos: filtered });
	};
	return (
		<Grid container>
			<Title>Videos</Title>
			{data.videos?.map((video, index) => (
				<Grid item md={5} style={{ margin: "20px" }}>
					<Title>{index}</Title>
					<TextField
						fullWidth
						value={video.url}
						label={"url"}
						onChange={(e) =>
							handleText(video.id, e.target.value, "url")
						}
						style={{ marginBottom: "10px" }}
					/>
					<TextField
						fullWidth
						value={video.title}
						label={"Título"}
						onChange={(e) =>
							handleText(video.id, e.target.value, "title")
						}
					/>
					<Button
						onClick={() => deleteVideo(video.id)}
						fullWidth
						variant="contained"
						color="secondary"
						style={{ marginTop: 10 }}
					>
						Eliminar
					</Button>
				</Grid>
			))}
			<Button
				style={{ marginTop: "50px" }}
				onClick={handleAddNew}
				fullWidth
				variant="contained"
			>
				Agregar nuevo
			</Button>
			<Title>Imágenes</Title>
			{data.images.map((image) => (
				<Item
					label={image.name}
					source={image.url}
					onUpdate={(file: any) => handleNewPic(image.id, file)}
				/>
			))}
			<Title>Textos</Title>
			{data.texts.map((item) => (
				<TextItem
					id={item.id}
					text={item.text}
					handleChange={(value: string) =>
						handleTextChange(item.id, value)
					}
				/>
			))}
		</Grid>
	);
}
