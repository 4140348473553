// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyArEfgHG1XEyBC5Gr2ZES0K2iwLhZq2EK0",
	authDomain: "danielbricen-cc9d9.firebaseapp.com",
	projectId: "danielbricen-cc9d9",
	storageBucket: "danielbricen-cc9d9.appspot.com",
	messagingSenderId: "439100035521",
	appId: "1:439100035521:web:320db5dfa2c3660eac5f40",
	measurementId: "G-WL35F2B8HK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
