import { Grid } from "@mui/material";
import TweetCard from "../twitter/Tweet";
import { isMobile } from "react-device-detect";
import Carousel from "react-material-ui-carousel";
import { IReports, ITweet } from "../../types/CommonTypes";
import { getImageById } from "../../commons/constants/helpers";
import Fade from "react-reveal/Fade";
import { useEffect } from "react";

interface IProps {
	item: IReports;
}

export default function Reports(props: IProps) {
	const { tweets, images } = props.item;

	if (isMobile)
		return (
			<Grid container style={{ justifyContent: "center" }}>
				<img
					alt="banner login"
					src={getImageById("title", { images: images, texts: [] })}
					style={{ width: "100%" }}
				/>

				<Carousel animation="slide" sx={{ width: "100%" }}>
					{tweets.map((item: ITweet, index: number) => (
						<TweetCard key={index} id={item.id} />
					))}
				</Carousel>
			</Grid>
		);
	else
		return (
			<Grid container style={{ justifyContent: "center" }}>
				<img
					alt="banner login"
					src={getImageById("title", { images: images, texts: [] })}
					style={{ width: "100%" }}
				/>
				<Grid item style={{ width: "100%" }}>
					<Fade right>
						{isMobile ? (
							<Carousel>
								{tweets.map((item: ITweet, index: number) => (
									<Grid item xs={12} md={12} key={index}>
										<TweetCard id={item.id} />
									</Grid>
								))}
							</Carousel>
						) : (
							<Carousel sx={{ height: "100vh" }}>
								{["a1"].map((row) => {
									return (
										<Grid container key={row}>
											{tweets
												.slice(
													0,
													tweets.length > 3
														? 3
														: tweets.length
												)
												.map(
													(
														item: ITweet,
														index: number
													) => (
														<TweetCard
															key={index}
															id={item.id}
														/>
													)
												)}
										</Grid>
									);
								})}
							</Carousel>
						)}
					</Fade>
				</Grid>
			</Grid>
		);
}
